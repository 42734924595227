body {
    width: 100%;
    font-size: 26px;

    *,
    & {
        color: #000 !important;
        text-align: left !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        overflow: visible !important;
        background-image: none !important;
        height: auto !important;
    }

    header.main,
    figure.transition,
    figure#mapbox,
    footer.main {
        display: none !important;
    }

    .hero div.slides div.slide figure figcaption {
        padding: 0;
    }
}
